













































































import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import {
  GET_COMPANY_DETAILS,
  SET_COMPANY
} from "@/store/modules/auth/constants";

import { UPDATE_APPROVAL_CRITERIA } from "@/store/modules/recruiter/constants";
import { UpdateApprovalCriteriaPayload } from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { cities } from "@/utils/global";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: { GoBackHeader },
  name: "ApprovalCriteria",
  data() {
    return {
      auto_approval: false,
      laptop_availability: false,
      wifi_availability: false,
      full_time_availability: false,
      location: "Dubai",
      locations: cities,
      n: 0
    };
  },
  mounted() {
    const approval_criteria =
      this.get_company_details.s3_path.approval_criteria;
    this.auto_approval = approval_criteria.auto_approval;
    this.laptop_availability = approval_criteria.laptop_availability;
    this.wifi_availability = approval_criteria.wifi_availability;
    this.full_time_availability = approval_criteria.full_time_availability;
    this.location = approval_criteria.location;
  },
  computed: {
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS
    })
  },
  methods: {
    ...mapActions("recruiter", {
      update_approval_criteria: UPDATE_APPROVAL_CRITERIA
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapMutations("auth", {
      set_company: SET_COMPANY
    }),
    // Function to update company assessment setting
    async saveChanges() {
      // API Call
      const payload: UpdateApprovalCriteriaPayload = {
        auto_approval: this.auto_approval,
        laptop_availability: this.laptop_availability,
        wifi_availability: this.wifi_availability,
        full_time_availability: this.full_time_availability,
        location: this.location
      };

      const response = await this.update_approval_criteria(payload);

      if (response) {
        this.set_company(response);
        this.set_root_notification(
          this.$t("recruiter.settings.approval-setting.update")
        );
      }
    },

    // Implement the logic to save changes
    // Function to filter locations
    filterLocation(item: string, query: string) {
      return item.toLowerCase().includes(query.toLowerCase());
    }
  }
});
